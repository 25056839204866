import axios from 'axios';

type BinanceKlineResponse = [
  number, // Open time
  string, // Open
  string, // High
  string, // Low
  string, // Close
  string, // Volume
  number, // Close time
  string, // Quote asset volume
  number, // Number of trades
  string, // Taker buy base asset volume
  string, // Taker buy quote asset volume
  string // Ignore
];

const getCorrectSymbol = (symbol: string, notation: string) => {
  // Convert USD to USDT for Binance compatibility
  const quoteAsset =
    notation.toUpperCase() === 'USD' ? 'USDT' : notation.toUpperCase();
  return `${symbol.toUpperCase()}${quoteAsset}`;
};

export const getKlineData = async (
  symbol: string,
  notation: string,
  interval: string,
  startDate: Date,
  endDate: Date,
  desiredLimit: number = 2000
) => {
  try {
    const correctedSymbol = getCorrectSymbol(symbol, notation);
    const chunks: BinanceKlineResponse[][] = [];
    let currentStartTime = startDate.getTime();
    const finalEndTime = endDate.getTime();

    // Keep fetching data until we have enough or reach the end time
    while (currentStartTime < finalEndTime) {
      const response = await axios.get<BinanceKlineResponse[]>(
        `https://api.binance.com/api/v3/klines`,
        {
          params: {
            symbol: correctedSymbol,
            interval,
            startTime: currentStartTime,
            endTime: finalEndTime,
            limit: 1000,
          },
        }
      );

      if (response.data.length === 0) break;

      chunks.push(response.data);

      // Update start time to fetch next chunk
      // Use the last candle's close time as the new start time
      currentStartTime = response.data[response.data.length - 1][6] + 1;

      // Break if we have enough data
      if (chunks.flat().length >= desiredLimit) break;
    }

    // Combine all chunks and limit to desired amount
    const allKlines = chunks.flat().slice(0, desiredLimit);

    return allKlines.map((kline) => ({
      openTime: kline[0],
      open: kline[1],
      high: kline[2],
      low: kline[3],
      close: kline[4],
      volume: kline[5],
      closeTime: kline[6],
      quoteVolume: kline[7],
      trades: kline[8],
      takerBuyBaseVolume: kline[9],
      takerBuyQuoteVolume: kline[10],
    }));
  } catch (error) {
    console.error('Error fetching Binance historical data:', error);
    if (axios.isAxiosError(error)) {
      console.error('Error details:', error.response?.data);
    }
    return [];
  }
};
