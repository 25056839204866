/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateSettings = /* GraphQL */ `subscription OnCreateSettings {
  onCreateSettings {
    id
    noOflayersToPlace
    noOfLayersToSkip
    fatFingerDropPerc
    skipAlltrades
    skipAllBtctrades
    skipAllUsdtrades
    collectFreeCoins
    lastAlertUpdatedDate
    baseType
    marketCycle
    hideInactivePairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSettingsSubscriptionVariables,
  APITypes.OnCreateSettingsSubscription
>;
export const onUpdateSettings = /* GraphQL */ `subscription OnUpdateSettings {
  onUpdateSettings {
    id
    noOflayersToPlace
    noOfLayersToSkip
    fatFingerDropPerc
    skipAlltrades
    skipAllBtctrades
    skipAllUsdtrades
    collectFreeCoins
    lastAlertUpdatedDate
    baseType
    marketCycle
    hideInactivePairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSettingsSubscriptionVariables,
  APITypes.OnUpdateSettingsSubscription
>;
export const onDeleteSettings = /* GraphQL */ `subscription OnDeleteSettings {
  onDeleteSettings {
    id
    noOflayersToPlace
    noOfLayersToSkip
    fatFingerDropPerc
    skipAlltrades
    skipAllBtctrades
    skipAllUsdtrades
    collectFreeCoins
    lastAlertUpdatedDate
    baseType
    marketCycle
    hideInactivePairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSettingsSubscriptionVariables,
  APITypes.OnDeleteSettingsSubscription
>;
export const onCreateBalances = /* GraphQL */ `subscription OnCreateBalances {
  onCreateBalances {
    id
    btc {
      free
      total
      real
      __typename
    }
    usd {
      free
      total
      real
      __typename
    }
    lastUpdated
    baseType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBalancesSubscriptionVariables,
  APITypes.OnCreateBalancesSubscription
>;
export const onUpdateBalances = /* GraphQL */ `subscription OnUpdateBalances {
  onUpdateBalances {
    id
    btc {
      free
      total
      real
      __typename
    }
    usd {
      free
      total
      real
      __typename
    }
    lastUpdated
    baseType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBalancesSubscriptionVariables,
  APITypes.OnUpdateBalancesSubscription
>;
export const onDeleteBalances = /* GraphQL */ `subscription OnDeleteBalances {
  onDeleteBalances {
    id
    btc {
      free
      total
      real
      __typename
    }
    usd {
      free
      total
      real
      __typename
    }
    lastUpdated
    baseType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBalancesSubscriptionVariables,
  APITypes.OnDeleteBalancesSubscription
>;
export const onCreateNotification = /* GraphQL */ `subscription OnCreateNotification {
  onCreateNotification {
    id
    coinID
    notation
    type
    message
    seen
    date
    baseType
    delAfterDealt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationSubscriptionVariables,
  APITypes.OnCreateNotificationSubscription
>;
export const onUpdateNotification = /* GraphQL */ `subscription OnUpdateNotification {
  onUpdateNotification {
    id
    coinID
    notation
    type
    message
    seen
    date
    baseType
    delAfterDealt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationSubscriptionVariables,
  APITypes.OnUpdateNotificationSubscription
>;
export const onDeleteNotification = /* GraphQL */ `subscription OnDeleteNotification {
  onDeleteNotification {
    id
    coinID
    notation
    type
    message
    seen
    date
    baseType
    delAfterDealt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSubscriptionVariables,
  APITypes.OnDeleteNotificationSubscription
>;
export const onCreateAlerts = /* GraphQL */ `subscription OnCreateAlerts {
  onCreateAlerts {
    id
    buyType
    buyOrBoughtId
    status
    buyOrder {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    boughtOrder {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    delAfterDealt
    recheckCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAlertsSubscriptionVariables,
  APITypes.OnCreateAlertsSubscription
>;
export const onUpdateAlerts = /* GraphQL */ `subscription OnUpdateAlerts {
  onUpdateAlerts {
    id
    buyType
    buyOrBoughtId
    status
    buyOrder {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    boughtOrder {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    delAfterDealt
    recheckCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAlertsSubscriptionVariables,
  APITypes.OnUpdateAlertsSubscription
>;
export const onDeleteAlerts = /* GraphQL */ `subscription OnDeleteAlerts {
  onDeleteAlerts {
    id
    buyType
    buyOrBoughtId
    status
    buyOrder {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    boughtOrder {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    delAfterDealt
    recheckCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAlertsSubscriptionVariables,
  APITypes.OnDeleteAlertsSubscription
>;
export const onCreateCoin = /* GraphQL */ `subscription OnCreateCoin {
  onCreateCoin {
    id
    is_active
    is_new
    name
    rank
    symbol
    type
    baseType
    pairs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCoinSubscriptionVariables,
  APITypes.OnCreateCoinSubscription
>;
export const onUpdateCoin = /* GraphQL */ `subscription OnUpdateCoin {
  onUpdateCoin {
    id
    is_active
    is_new
    name
    rank
    symbol
    type
    baseType
    pairs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCoinSubscriptionVariables,
  APITypes.OnUpdateCoinSubscription
>;
export const onDeleteCoin = /* GraphQL */ `subscription OnDeleteCoin {
  onDeleteCoin {
    id
    is_active
    is_new
    name
    rank
    symbol
    type
    baseType
    pairs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCoinSubscriptionVariables,
  APITypes.OnDeleteCoinSubscription
>;
export const onCreatePairInfo = /* GraphQL */ `subscription OnCreatePairInfo {
  onCreatePairInfo {
    id
    Coin {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    notation
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
      __typename
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    baseType
    layeredBuyBids {
      nextToken
      __typename
    }
    layeredBuyBoughts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePairInfoSubscriptionVariables,
  APITypes.OnCreatePairInfoSubscription
>;
export const onUpdatePairInfo = /* GraphQL */ `subscription OnUpdatePairInfo {
  onUpdatePairInfo {
    id
    Coin {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    notation
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
      __typename
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    baseType
    layeredBuyBids {
      nextToken
      __typename
    }
    layeredBuyBoughts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePairInfoSubscriptionVariables,
  APITypes.OnUpdatePairInfoSubscription
>;
export const onDeletePairInfo = /* GraphQL */ `subscription OnDeletePairInfo {
  onDeletePairInfo {
    id
    Coin {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    notation
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
      __typename
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    baseType
    layeredBuyBids {
      nextToken
      __typename
    }
    layeredBuyBoughts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePairInfoSubscriptionVariables,
  APITypes.OnDeletePairInfoSubscription
>;
export const onCreateOrderBids = /* GraphQL */ `subscription OnCreateOrderBids {
  onCreateOrderBids {
    id
    pairID
    buyPercent
    originalBuyPrice
    buyPrice
    highPrice
    buyHit
    bidTime
    buyColor
    invested
    stoploss
    stoplossPrice
    baseType
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderBidsSubscriptionVariables,
  APITypes.OnCreateOrderBidsSubscription
>;
export const onUpdateOrderBids = /* GraphQL */ `subscription OnUpdateOrderBids {
  onUpdateOrderBids {
    id
    pairID
    buyPercent
    originalBuyPrice
    buyPrice
    highPrice
    buyHit
    bidTime
    buyColor
    invested
    stoploss
    stoplossPrice
    baseType
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderBidsSubscriptionVariables,
  APITypes.OnUpdateOrderBidsSubscription
>;
export const onDeleteOrderBids = /* GraphQL */ `subscription OnDeleteOrderBids {
  onDeleteOrderBids {
    id
    pairID
    buyPercent
    originalBuyPrice
    buyPrice
    highPrice
    buyHit
    bidTime
    buyColor
    invested
    stoploss
    stoplossPrice
    baseType
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderBidsSubscriptionVariables,
  APITypes.OnDeleteOrderBidsSubscription
>;
export const onCreateOrderBoughts = /* GraphQL */ `subscription OnCreateOrderBoughts {
  onCreateOrderBoughts {
    id
    pairID
    highPrice
    buyOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    sellOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    realBuyOrderPlaced
    realSellOrderPlaced
    realBuyOrderStatus
    realSellOrderStatus
    buyPercent
    originalBuyPrice
    buyPrice
    bidTime
    buyTime
    buyColor
    stoploss
    stoplossPrice
    profitSecured
    profitMoved
    sellTime
    invested
    baseType
    buyTweetId
    sellTweetId
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderBoughtsSubscriptionVariables,
  APITypes.OnCreateOrderBoughtsSubscription
>;
export const onUpdateOrderBoughts = /* GraphQL */ `subscription OnUpdateOrderBoughts {
  onUpdateOrderBoughts {
    id
    pairID
    highPrice
    buyOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    sellOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    realBuyOrderPlaced
    realSellOrderPlaced
    realBuyOrderStatus
    realSellOrderStatus
    buyPercent
    originalBuyPrice
    buyPrice
    bidTime
    buyTime
    buyColor
    stoploss
    stoplossPrice
    profitSecured
    profitMoved
    sellTime
    invested
    baseType
    buyTweetId
    sellTweetId
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderBoughtsSubscriptionVariables,
  APITypes.OnUpdateOrderBoughtsSubscription
>;
export const onDeleteOrderBoughts = /* GraphQL */ `subscription OnDeleteOrderBoughts {
  onDeleteOrderBoughts {
    id
    pairID
    highPrice
    buyOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    sellOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    realBuyOrderPlaced
    realSellOrderPlaced
    realBuyOrderStatus
    realSellOrderStatus
    buyPercent
    originalBuyPrice
    buyPrice
    bidTime
    buyTime
    buyColor
    stoploss
    stoplossPrice
    profitSecured
    profitMoved
    sellTime
    invested
    baseType
    buyTweetId
    sellTweetId
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderBoughtsSubscriptionVariables,
  APITypes.OnDeleteOrderBoughtsSubscription
>;
export const onCreatePortfolio = /* GraphQL */ `subscription OnCreatePortfolio {
  onCreatePortfolio {
    id
    date
    portfolioValueUSD
    portfolioValueBTC
    totalMarketCapUSD
    totalCryptosHeld
    notableEvents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePortfolioSubscriptionVariables,
  APITypes.OnCreatePortfolioSubscription
>;
export const onUpdatePortfolio = /* GraphQL */ `subscription OnUpdatePortfolio {
  onUpdatePortfolio {
    id
    date
    portfolioValueUSD
    portfolioValueBTC
    totalMarketCapUSD
    totalCryptosHeld
    notableEvents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePortfolioSubscriptionVariables,
  APITypes.OnUpdatePortfolioSubscription
>;
export const onDeletePortfolio = /* GraphQL */ `subscription OnDeletePortfolio {
  onDeletePortfolio {
    id
    date
    portfolioValueUSD
    portfolioValueBTC
    totalMarketCapUSD
    totalCryptosHeld
    notableEvents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePortfolioSubscriptionVariables,
  APITypes.OnDeletePortfolioSubscription
>;
