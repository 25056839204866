import { API, graphqlOperation } from 'aws-amplify';
import { createPairInfo, createCoin } from '../graphql/mutations';
import {
  CreateAlertsInput,
  CreateOrderBidsInput,
  CreateOrderBoughtsInput,
  CreatePairInfoInput,
  CreatePortfolioInput,
  NotificationType,
  Portfolio,
  UpdateOrderBidsInput,
  UpdateOrderBoughtsInput,
  UpdatePairInfoInput,
} from '../API';

import * as customQuery from '../custom-graphql/queries'; // @ts-ignore
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import {
  Balances,
  GlobalSettings,
  NotificationsProps,
  TradeNotations,
} from '../types';
import { daysInSeconds } from '../utils/calculations/timeCalculation';
import { runFunc } from '../utils/catchErrors';
import { pure, transforCoinInfo } from '../utils/transformations';

type BidOrderType = {
  coinID: string;
  notation: TradeNotations;
  buyPrice: number;
  buyPercent: number;
  buyColor: string;
  invested?: number | null;
  highPrice: number;
};

class AwsDatabase {
  makeTheCoinNonTradeable = async (
    coinID: string,
    dontTrade: string,
    notation: string
  ) => {
    const input = {
      id: `${coinID}-${notation}`,
      dontTrade: !dontTrade,
    };
    const result = await runFunc(
      async () =>
        API.graphql({ query: mutations.updatePairInfo, variables: { input } }),
      `Error when saving dont trade for coin id ${coinID}-${notation}`
    );
    // console.log(result);
    return result;
  };

  getBalances = async () => {
    const result: any = await runFunc(
      async () =>
        API.graphql({
          query: queries.getBalances,
          variables: { id: 'balances' },
        }),
      `Error when querying for balances`
    );
    // console.log(result);
    return result.data.getBalances;
  };

  getCoinsWithPairAndOrdersForDashboard = async () => {
    const result: any = await runFunc(
      async () =>
        API.graphql({
          query: customQuery.getCoinsWithPairAndOrdersForDashboard,
        }),
      `Error when querying for coins with pair and orders for dashboard`
    );
    return result.data.listCoins.items;
  };

  listPortfolio = async (): Promise<Portfolio[]> => {
    const result: any = await runFunc(
      async () =>
        API.graphql({
          query: queries.listPortfolios,
        }),
      `Error when querying for portfolio`
    );

    return result.data.listPortfolios.items;
  };

  getGlobalSettings = async () => {
    const result: any = await runFunc(
      async () =>
        API.graphql({
          query: queries.getSettings,
          variables: { id: 'settings' },
        }),
      `Error when querying for settings`
    );

    return result.data.getSettings;
  };

  getCoinDetailsWithBuysAndBoughts = async (pairID: string) => {
    const result: any = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(customQuery.getPairInfoWithOrders, { id: pairID })
        ),
      `Error when querying for pair info ${pairID}`
    );

    return result.data.getPairInfo;
  };

  getCoinDetailsWithBuyOrders = async (pairID: string) => {
    const result: any = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(customQuery.getPairInfoWithBuyOrders, { id: pairID })
        ),
      `Error when querying for pair info ${pairID}`
    );

    return result.data.getPairInfo;
  };

  getOrderBoughtInfo = async (boughtOrderID: string) => {
    const result: any = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(queries.getOrderBoughts, { id: boughtOrderID })
        ),
      `Error when saving querying for bought order ${boughtOrderID}`
    );

    return result.data.getOrderBoughts;
  };

  getAllNotifications = async () => {
    const result: any = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(queries.listNotifications, {
            filter: { seen: { eq: false } },
            limit: 1000,
          })
        ),
      `Error when querying for notifications`
    );

    return result.data.listNotifications;
  };

  getTopCoins = async () => {
    const result: any = await runFunc(
      async () =>
        API.graphql({
          query: queries.listCoins,
        }),
      `Error when querying for AllCoins Info`
    );

    return result.data.listCoins.items;
  };

  getTopCoinsWithBidOrders = async () => {
    const result: any = await runFunc(
      async () =>
        API.graphql({
          query: customQuery.listCoinsWithBuyOrders,
        }),
      `Error when querying for AllCoins with bid orders Info`
    );

    return result.data.listCoins.items;
  };

  getAlertByID = async (alertId: string) => {
    const result: any = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(customQuery.getAlertWithOrderAndPairInfo, {
            id: alertId,
          })
        ),
      `Error when querying for alert ${alertId}`
    );

    return result.data.getAlerts;
  };

  getAlertsToBeProcessed = async () => {
    const result: any = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(customQuery.listAlertsNotProessed, {
            filter: { status: { ne: 'complete' } },
            limit: 1000,
          })
        ),
      `Error when querying for alerts`
    );

    return result.data.listAlerts.items;
  };

  saveBtcBal = async (balances: Balances) => {
    const input = {
      ...balances,
      id: 'balances',
      lastUpdated: +new Date(),
    };
    const result = await runFunc(
      async () =>
        API.graphql(graphqlOperation(mutations.updateBalances, { input })),
      `Error when saving new balance`
    );
    console.log('------ Binance Balance updated ✅ -------');
    return result;
  };

  savePortfolio = async (portfolio: CreatePortfolioInput) => {
    const input = {
      ...portfolio,
    };
    const result = await runFunc(
      async () =>
        API.graphql(graphqlOperation(mutations.createPortfolio, { input })),
      `Error when saving new Portfolio`
    );
    console.log(`------ Portfolio updated for ${portfolio.date} ✅ -------`);
    return result;
  };

  changeCoinPriceAndTrade = async (
    coinID: string,
    notation: TradeNotations,
    higestPrice: number,
    relativeHigh: number,
    paperTrade: boolean
  ) => {
    const input = {
      id: `${coinID}-${notation}`,
      dontTrade: paperTrade,
      relativeHigh,
      higestPrice,
    };
    const result = await runFunc(
      async () =>
        API.graphql({ query: mutations.updatePairInfo, variables: { input } }),
      `Error when saving dont trade for coin id ${coinID}-${notation}`
    );
    // console.log(result);
    return result;
  };

  saveGlobalSetting = async (settings: GlobalSettings) => {
    const input = {
      ...settings,
      id: 'settings',
    };
    const result = await runFunc(
      async () =>
        API.graphql(graphqlOperation(mutations.updateSettings, { input })),
      `Error when saving new settings`
    );
    // console.log(result);
    return result;
  };

  createCoin = async (coin: any) => {
    const data = pure(transforCoinInfo(coin));

    const result = await runFunc(
      async () => API.graphql(graphqlOperation(createCoin, { input: data })),
      `Error when saving coin info ${coin.id}`
    );
    return result;
  };

  createPairInfo = async (pairInfo: CreatePairInfoInput) => {
    const result = await runFunc(
      async () =>
        API.graphql(graphqlOperation(createPairInfo, { input: pairInfo })),
      `Error when saving pair info ${pairInfo.id}`
    );
    return result;
  };

  createAlert = async (data: CreateAlertsInput) => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      return null;
    }

    const result = await runFunc(
      async () =>
        API.graphql(graphqlOperation(mutations.createAlerts, { input: data })),
      `error!! when creating alert for ${data.buyOrBoughtId}`
    );

    console.log('added alert for --> ', data.buyOrBoughtId);
    return result;
  };

  createBidOrder = async ({
    coinID,
    notation,
    buyPrice,
    buyPercent,
    buyColor,
    highPrice,
  }: BidOrderType) => {
    const timeInMs = +new Date();
    const data: CreateOrderBidsInput = {
      id: `${coinID}-${notation}-bids-${buyPercent}-${timeInMs}`,
      pairID: `${coinID}-${notation}`,
      orderBidsPairInfoId: `${coinID}-${notation}`,
      highPrice,
      buyPercent,
      originalBuyPrice: buyPrice,
      buyPrice,
      bidTime: timeInMs,
      buyColor,
      baseType: 'OrderBids',
    };

    // console.log('layer', buyPercent, 'data = ', data);
    // return;

    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.createOrderBids, { input: data })
        ),
      `error!! when saving bids order for ${coinID}-${notation} -- percent -- ${buyPercent}`
    );
    return result;
  };

  createBoughtOrderManual = async (
    coinID: string,
    notation: TradeNotations,
    buyPrice: number,
    buyPercent: number,
    buyTime: number,
    buyColor: string,
    invested: number,
    highPrice: number,
    realOrderProps: {} | undefined
  ) => {
    const data: CreateOrderBoughtsInput = {
      id: `${coinID}-${notation}-boughts-${buyPercent}-${+new Date()}`,
      pairID: `${coinID}-${notation}`,
      orderBoughtsPairInfoId: `${coinID}-${notation}`,
      highPrice,
      buyPercent,
      originalBuyPrice: buyPrice,
      buyPrice,
      buyTime: buyTime as unknown as number,
      bidTime: buyTime as unknown as number,
      buyColor,
      invested,
      baseType: 'boughtOrder',
      ...(realOrderProps && realOrderProps),
    };

    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.createOrderBoughts, { input: data })
        ),
      `error!! when saving bought order for ${coinID}-${notation} -- percent -- ${buyPercent}`
    );
    return result;
  };

  createBoughtOrderTransformed = async (data: CreateOrderBoughtsInput) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.createOrderBoughts, { input: data })
        ),
      `error!! when saving bought order for ${data.id}`
    );
    return result;
  };

  addNewNotification = async (
    id: string,
    coinID: string,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean
  ) => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      return null;
    }

    const dateInMs = +new Date().getTime();
    const data = {
      id: id + dateInMs,
      coinID,
      notation,
      type,
      message,
      seen,
      date: dateInMs,
      baseType: 'notification',
    };
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.createNotification, { input: data })
        ),
      `error!! when saving new notification for ${data.id}`
    );
    return result;
  };

  updatePairInfoPrices = async (pairInfo: UpdatePairInfoInput) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.updatePairInfo, { input: pairInfo })
        ),
      `error!! when saving pair info for ${pairInfo.id}`
    );
    return result;
  };

  updateCoin = async (coin: any) => {
    const result = await runFunc(
      async () =>
        API.graphql(graphqlOperation(mutations.updateCoin, { input: coin })),
      `error!! when saving coin info for ${coin.id}`
    );
    return result;
  };

  updateCoinBidOrder = async (updateOrder: UpdateOrderBidsInput) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.updateOrderBids, { input: updateOrder })
        ),
      `error!! when saving bids for ${updateOrder.id}`
    );
    return result;
  };

  updateCoinBoughtOrder = async (updateOrder: UpdateOrderBoughtsInput) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.updateOrderBoughts, { input: updateOrder })
        ),
      `error!! when saving updated bought order for ${updateOrder.id}`
    );
    return result;
  };

  markNotificationAsRead = async (id: string) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.updateNotification, {
            input: { id, seen: true, delAfterDealt: daysInSeconds(2) },
          })
        ),
      `error!! when updating notification for ${id}`
    );
    return result;
  };

  markAlertAsComplete = async (id: string) => {
    if (process.env.REACT_APP_NODE_ENV === 'development') return null;

    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.updateAlerts, {
            input: {
              id,
              status: 'complete',
              delAfterDealt: daysInSeconds(2),
            },
          })
        ),
      `error!! when updating alert for ${id}`
    );
    return result;
  };

  markAllNotificationAsRead = async (notifications: NotificationsProps) => {
    const status = Object.entries(notifications).map((notification) =>
      this.markNotificationAsRead(notification[1].id)
    );
    await Promise.all(status);
    console.log('all updated');
  };

  deleteCoinBoughtOrder = async (id: string) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.deleteOrderBoughts, { input: { id } })
        ),
      `error!! when deleting bought for ${id}`
    );
    return result;
  };

  deleteCoinBidOrder = async (id: string) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.deleteOrderBids, { input: { id } })
        ),
      `error!! when deleting bought for ${id}`
    );
    return result;
  };

  deleteCoinPairInfo = async (id: string) => {
    const result = await runFunc(
      async () =>
        API.graphql(
          graphqlOperation(mutations.deletePairInfo, { input: { id } })
        ),
      `error!! when deleting pairInfo for ${id}`
    );
    return result;
  };

  deleteCoin = async (id: string) => {
    const result = await runFunc(
      async () =>
        API.graphql(graphqlOperation(mutations.deleteCoin, { input: { id } })),
      `error!! when deleting coin for ${id}`
    );
    return result;
  };
}

export default AwsDatabase;
