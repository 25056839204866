import React from 'react';
import { Settings } from '../../types';
import AwsDatabase from '../../database/Database';

const AwsDB = new AwsDatabase();

export const useGlobalSettings = () => {
  const [settings, setSettings] = React.useState<Settings['globalSettings']>();

  React.useEffect(() => {
    const fetchSettings = async () => {
      const settingsData = await AwsDB.getGlobalSettings();
      setSettings(settingsData);
    };
    fetchSettings();
  }, []);

  return settings;
};
