import React, { useState } from 'react';
import { Coin, OrderBids, PairInfo } from '../../../API';
import {
  areBuysInTradeAndHasStoploss,
  isRealBoughtOrder,
} from '../../../chart-specifics/coinCalculation';
import { DashboardCoin, SortAlertArgs, SortTradeArgs } from '../../../types';
import { getBEPriceForNotSoldCoins } from '../../../utils/calculations/percentageAndprice';
import { ReactTable } from './ReactTable';
import {
  defaulthideColumnsOnMobile,
  getNoOfAlertTriggered,
  getNoOfTradesOn,
  sortAlerts,
  sortTrades,
} from './coinsTableParts';

type CoinsTableProps = {
  istradePairBtc: boolean;
  untransformedData: Coin[];
  activateDontTradeForCoin: () => void;
  hideInactivePairs: boolean;
};

export const transformCoinsTableData = (
  istradePairBtc: boolean,
  allCoinData: Coin[],
  hideInactivePairs: boolean
): DashboardCoin[] => {
  let i = 0;
  const coin: DashboardCoin[] = [];
  const allCoinsData: DashboardCoin[] = [];
  const notation = istradePairBtc ? 'btc' : 'usd';
  const alternateNotation = istradePairBtc ? 'usd' : 'btc';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.values(allCoinData).forEach((data: any) => {
    const tradePair = data.pairs.items.filter(
      (pair: PairInfo) => pair.id === `${data.id}-${notation}`
    )[0];

    if (hideInactivePairs && tradePair?.dontTrade) {
      return;
    }

    const alternatePair = data.pairs.items.filter(
      (pair: PairInfo) => pair.id === `${data.id}-${alternateNotation}`
    )[0];

    if (!tradePair || !alternatePair) {
      console.log(`could not find trade pair for ${data.id}-${notation}
      `);
      return;
    }

    const { rank, id, symbol } = data;

    const { currentPrice: currentAlternatePrice } = alternatePair;
    const {
      currentPrice,
      relativeHigh,
      higestPrice,
      layeredBuyBids,
      layeredBuyBoughts,
      dontTrade,
    } = tradePair;

    const boughtOrders = layeredBuyBoughts?.items;
    const buyOrders = layeredBuyBids?.items;

    const fromATH = +(
      ((higestPrice - currentPrice) / higestPrice) *
      100
    ).toFixed(2);

    const fromFourMonthATH = +(
      ((relativeHigh - currentPrice) / relativeHigh) *
      100
    ).toFixed(2);

    const { isCurrentlyInTrade, hasStopLossPrice, hasRealOrder, finalTrade } =
      areBuysInTradeAndHasStoploss(boughtOrders);

    const buyBoughts = hasRealOrder
      ? boughtOrders?.filter(isRealBoughtOrder)
      : boughtOrders;

    const breakEvenPrice = getBEPriceForNotSoldCoins(currentPrice, buyBoughts);

    const stopLossPerc =
      +((currentPrice - breakEvenPrice) / breakEvenPrice) * 100;

    coin[i] = {
      rank,
      coinDetails: [id, symbol, dontTrade || null],
      currentPrice,
      currentAlternatePrice,
      fromFourMonthATH,
      relativeHigh,
      fromATH,
      higestPrice,
      alerts: {
        hasAlertTriggered: Boolean(buyOrders && buyOrders.length),
        hasStoploss: Boolean(
          buyOrders && buyOrders.some((bids: OrderBids) => bids.stoploss)
        ),
      },
      tradeDetails: {
        isCurrentlyInTrade,
        hasStopLossPrice,
        stopLossPerc: stopLossPerc || -Infinity,
        hasRealOrder,
        finalTrade,
      },
      symbol,
    };
    allCoinsData.push(coin[i]);
    i += 1;
  });

  return allCoinsData;
};

export const CoinsTable: React.FC<CoinsTableProps> = ({
  istradePairBtc,
  untransformedData,
  activateDontTradeForCoin,
  hideInactivePairs,
}) => {
  const transformedCoins = React.useMemo(
    () =>
      transformCoinsTableData(
        istradePairBtc,
        untransformedData,
        hideInactivePairs
      ),
    [istradePairBtc, untransformedData, hideInactivePairs]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Coin',
        columns: [
          {
            Header: 'Rank',
            accessor: 'rank',
            width: 50,
          },
          {
            Header: 'Trade Pair',
            accessor: 'coinDetails',
            className: 'marketName',
            width: 110,
          },
          {
            Header: 'Current Price',
            accessor: 'currentPrice',
            width: 200,
          },
          {
            Header: '% from local High',
            accessor: 'fromFourMonthATH',
            width: 160,
          },
          {
            Header: 'local High',
            accessor: 'relativeHigh',
            width: 130,
          },
          {
            Header: '% from ATH',
            width: 140,
            accessor: 'fromATH',
          },
          {
            Header: 'All Time High',
            accessor: 'higestPrice',
            width: 130,
          },
        ],
      },
      {
        Header: 'Extra',
        columns: [
          {
            Header: transformedCoins && getNoOfAlertTriggered(transformedCoins),
            accessor: 'alerts',
            width: 160,
            sortType: (
              a: SortAlertArgs,
              b: SortAlertArgs,
              columnId: string,
              desc: number
            ) => sortAlerts(a, b, columnId, desc),
          },
          {
            Header: () => getNoOfTradesOn(transformedCoins),
            accessor: 'tradeDetails',
            width: 170,
            sortType: (
              a: SortTradeArgs,
              b: SortTradeArgs,
              columnId: string,
              desc: number
            ) => sortTrades(a, b, columnId, desc),
          },
          {
            Header: 'Action',
            accessor: 'action',
            width: 120,
          },
        ],
      },
    ],
    [transformedCoins]
  );

  return (
    <div className="col-lg-10 coinholdings">
      <ReactTable
        columns={columns}
        data={transformedCoins}
        sortAccessor="tradeDetails"
        istradePairBtc={istradePairBtc}
        descending
        hideColumnsOnMobile={defaulthideColumnsOnMobile}
      />
    </div>
  );
};
