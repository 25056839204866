/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createSettings = /* GraphQL */ `mutation CreateSettings(
  $input: CreateSettingsInput!
  $condition: ModelSettingsConditionInput
) {
  createSettings(input: $input, condition: $condition) {
    id
    noOflayersToPlace
    noOfLayersToSkip
    fatFingerDropPerc
    skipAlltrades
    skipAllBtctrades
    skipAllUsdtrades
    collectFreeCoins
    lastAlertUpdatedDate
    baseType
    marketCycle
    hideInactivePairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSettingsMutationVariables,
  APITypes.CreateSettingsMutation
>;
export const updateSettings = /* GraphQL */ `mutation UpdateSettings(
  $input: UpdateSettingsInput!
  $condition: ModelSettingsConditionInput
) {
  updateSettings(input: $input, condition: $condition) {
    id
    noOflayersToPlace
    noOfLayersToSkip
    fatFingerDropPerc
    skipAlltrades
    skipAllBtctrades
    skipAllUsdtrades
    collectFreeCoins
    lastAlertUpdatedDate
    baseType
    marketCycle
    hideInactivePairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSettingsMutationVariables,
  APITypes.UpdateSettingsMutation
>;
export const deleteSettings = /* GraphQL */ `mutation DeleteSettings(
  $input: DeleteSettingsInput!
  $condition: ModelSettingsConditionInput
) {
  deleteSettings(input: $input, condition: $condition) {
    id
    noOflayersToPlace
    noOfLayersToSkip
    fatFingerDropPerc
    skipAlltrades
    skipAllBtctrades
    skipAllUsdtrades
    collectFreeCoins
    lastAlertUpdatedDate
    baseType
    marketCycle
    hideInactivePairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSettingsMutationVariables,
  APITypes.DeleteSettingsMutation
>;
export const createBalances = /* GraphQL */ `mutation CreateBalances(
  $input: CreateBalancesInput!
  $condition: ModelBalancesConditionInput
) {
  createBalances(input: $input, condition: $condition) {
    id
    btc {
      free
      total
      real
      __typename
    }
    usd {
      free
      total
      real
      __typename
    }
    lastUpdated
    baseType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBalancesMutationVariables,
  APITypes.CreateBalancesMutation
>;
export const updateBalances = /* GraphQL */ `mutation UpdateBalances(
  $input: UpdateBalancesInput!
  $condition: ModelBalancesConditionInput
) {
  updateBalances(input: $input, condition: $condition) {
    id
    btc {
      free
      total
      real
      __typename
    }
    usd {
      free
      total
      real
      __typename
    }
    lastUpdated
    baseType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBalancesMutationVariables,
  APITypes.UpdateBalancesMutation
>;
export const deleteBalances = /* GraphQL */ `mutation DeleteBalances(
  $input: DeleteBalancesInput!
  $condition: ModelBalancesConditionInput
) {
  deleteBalances(input: $input, condition: $condition) {
    id
    btc {
      free
      total
      real
      __typename
    }
    usd {
      free
      total
      real
      __typename
    }
    lastUpdated
    baseType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBalancesMutationVariables,
  APITypes.DeleteBalancesMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    coinID
    notation
    type
    message
    seen
    date
    baseType
    delAfterDealt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    coinID
    notation
    type
    message
    seen
    date
    baseType
    delAfterDealt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    coinID
    notation
    type
    message
    seen
    date
    baseType
    delAfterDealt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createAlerts = /* GraphQL */ `mutation CreateAlerts(
  $input: CreateAlertsInput!
  $condition: ModelAlertsConditionInput
) {
  createAlerts(input: $input, condition: $condition) {
    id
    buyType
    buyOrBoughtId
    status
    buyOrder {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    boughtOrder {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    delAfterDealt
    recheckCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAlertsMutationVariables,
  APITypes.CreateAlertsMutation
>;
export const updateAlerts = /* GraphQL */ `mutation UpdateAlerts(
  $input: UpdateAlertsInput!
  $condition: ModelAlertsConditionInput
) {
  updateAlerts(input: $input, condition: $condition) {
    id
    buyType
    buyOrBoughtId
    status
    buyOrder {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    boughtOrder {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    delAfterDealt
    recheckCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAlertsMutationVariables,
  APITypes.UpdateAlertsMutation
>;
export const deleteAlerts = /* GraphQL */ `mutation DeleteAlerts(
  $input: DeleteAlertsInput!
  $condition: ModelAlertsConditionInput
) {
  deleteAlerts(input: $input, condition: $condition) {
    id
    buyType
    buyOrBoughtId
    status
    buyOrder {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    boughtOrder {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    delAfterDealt
    recheckCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAlertsMutationVariables,
  APITypes.DeleteAlertsMutation
>;
export const createCoin = /* GraphQL */ `mutation CreateCoin(
  $input: CreateCoinInput!
  $condition: ModelCoinConditionInput
) {
  createCoin(input: $input, condition: $condition) {
    id
    is_active
    is_new
    name
    rank
    symbol
    type
    baseType
    pairs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCoinMutationVariables,
  APITypes.CreateCoinMutation
>;
export const updateCoin = /* GraphQL */ `mutation UpdateCoin(
  $input: UpdateCoinInput!
  $condition: ModelCoinConditionInput
) {
  updateCoin(input: $input, condition: $condition) {
    id
    is_active
    is_new
    name
    rank
    symbol
    type
    baseType
    pairs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCoinMutationVariables,
  APITypes.UpdateCoinMutation
>;
export const deleteCoin = /* GraphQL */ `mutation DeleteCoin(
  $input: DeleteCoinInput!
  $condition: ModelCoinConditionInput
) {
  deleteCoin(input: $input, condition: $condition) {
    id
    is_active
    is_new
    name
    rank
    symbol
    type
    baseType
    pairs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCoinMutationVariables,
  APITypes.DeleteCoinMutation
>;
export const createPairInfo = /* GraphQL */ `mutation CreatePairInfo(
  $input: CreatePairInfoInput!
  $condition: ModelPairInfoConditionInput
) {
  createPairInfo(input: $input, condition: $condition) {
    id
    Coin {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    notation
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
      __typename
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    baseType
    layeredBuyBids {
      nextToken
      __typename
    }
    layeredBuyBoughts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePairInfoMutationVariables,
  APITypes.CreatePairInfoMutation
>;
export const updatePairInfo = /* GraphQL */ `mutation UpdatePairInfo(
  $input: UpdatePairInfoInput!
  $condition: ModelPairInfoConditionInput
) {
  updatePairInfo(input: $input, condition: $condition) {
    id
    Coin {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    notation
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
      __typename
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    baseType
    layeredBuyBids {
      nextToken
      __typename
    }
    layeredBuyBoughts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePairInfoMutationVariables,
  APITypes.UpdatePairInfoMutation
>;
export const deletePairInfo = /* GraphQL */ `mutation DeletePairInfo(
  $input: DeletePairInfoInput!
  $condition: ModelPairInfoConditionInput
) {
  deletePairInfo(input: $input, condition: $condition) {
    id
    Coin {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    notation
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
      __typename
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    baseType
    layeredBuyBids {
      nextToken
      __typename
    }
    layeredBuyBoughts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePairInfoMutationVariables,
  APITypes.DeletePairInfoMutation
>;
export const createOrderBids = /* GraphQL */ `mutation CreateOrderBids(
  $input: CreateOrderBidsInput!
  $condition: ModelOrderBidsConditionInput
) {
  createOrderBids(input: $input, condition: $condition) {
    id
    pairID
    buyPercent
    originalBuyPrice
    buyPrice
    highPrice
    buyHit
    bidTime
    buyColor
    invested
    stoploss
    stoplossPrice
    baseType
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderBidsMutationVariables,
  APITypes.CreateOrderBidsMutation
>;
export const updateOrderBids = /* GraphQL */ `mutation UpdateOrderBids(
  $input: UpdateOrderBidsInput!
  $condition: ModelOrderBidsConditionInput
) {
  updateOrderBids(input: $input, condition: $condition) {
    id
    pairID
    buyPercent
    originalBuyPrice
    buyPrice
    highPrice
    buyHit
    bidTime
    buyColor
    invested
    stoploss
    stoplossPrice
    baseType
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderBidsMutationVariables,
  APITypes.UpdateOrderBidsMutation
>;
export const deleteOrderBids = /* GraphQL */ `mutation DeleteOrderBids(
  $input: DeleteOrderBidsInput!
  $condition: ModelOrderBidsConditionInput
) {
  deleteOrderBids(input: $input, condition: $condition) {
    id
    pairID
    buyPercent
    originalBuyPrice
    buyPrice
    highPrice
    buyHit
    bidTime
    buyColor
    invested
    stoploss
    stoplossPrice
    baseType
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderBidsMutationVariables,
  APITypes.DeleteOrderBidsMutation
>;
export const createOrderBoughts = /* GraphQL */ `mutation CreateOrderBoughts(
  $input: CreateOrderBoughtsInput!
  $condition: ModelOrderBoughtsConditionInput
) {
  createOrderBoughts(input: $input, condition: $condition) {
    id
    pairID
    highPrice
    buyOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    sellOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    realBuyOrderPlaced
    realSellOrderPlaced
    realBuyOrderStatus
    realSellOrderStatus
    buyPercent
    originalBuyPrice
    buyPrice
    bidTime
    buyTime
    buyColor
    stoploss
    stoplossPrice
    profitSecured
    profitMoved
    sellTime
    invested
    baseType
    buyTweetId
    sellTweetId
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderBoughtsMutationVariables,
  APITypes.CreateOrderBoughtsMutation
>;
export const updateOrderBoughts = /* GraphQL */ `mutation UpdateOrderBoughts(
  $input: UpdateOrderBoughtsInput!
  $condition: ModelOrderBoughtsConditionInput
) {
  updateOrderBoughts(input: $input, condition: $condition) {
    id
    pairID
    highPrice
    buyOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    sellOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    realBuyOrderPlaced
    realSellOrderPlaced
    realBuyOrderStatus
    realSellOrderStatus
    buyPercent
    originalBuyPrice
    buyPrice
    bidTime
    buyTime
    buyColor
    stoploss
    stoplossPrice
    profitSecured
    profitMoved
    sellTime
    invested
    baseType
    buyTweetId
    sellTweetId
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderBoughtsMutationVariables,
  APITypes.UpdateOrderBoughtsMutation
>;
export const deleteOrderBoughts = /* GraphQL */ `mutation DeleteOrderBoughts(
  $input: DeleteOrderBoughtsInput!
  $condition: ModelOrderBoughtsConditionInput
) {
  deleteOrderBoughts(input: $input, condition: $condition) {
    id
    pairID
    highPrice
    buyOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    sellOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    realBuyOrderPlaced
    realSellOrderPlaced
    realBuyOrderStatus
    realSellOrderStatus
    buyPercent
    originalBuyPrice
    buyPrice
    bidTime
    buyTime
    buyColor
    stoploss
    stoplossPrice
    profitSecured
    profitMoved
    sellTime
    invested
    baseType
    buyTweetId
    sellTweetId
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderBoughtsMutationVariables,
  APITypes.DeleteOrderBoughtsMutation
>;
export const createPortfolio = /* GraphQL */ `mutation CreatePortfolio(
  $input: CreatePortfolioInput!
  $condition: ModelPortfolioConditionInput
) {
  createPortfolio(input: $input, condition: $condition) {
    id
    date
    portfolioValueUSD
    portfolioValueBTC
    totalMarketCapUSD
    totalCryptosHeld
    notableEvents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePortfolioMutationVariables,
  APITypes.CreatePortfolioMutation
>;
export const updatePortfolio = /* GraphQL */ `mutation UpdatePortfolio(
  $input: UpdatePortfolioInput!
  $condition: ModelPortfolioConditionInput
) {
  updatePortfolio(input: $input, condition: $condition) {
    id
    date
    portfolioValueUSD
    portfolioValueBTC
    totalMarketCapUSD
    totalCryptosHeld
    notableEvents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePortfolioMutationVariables,
  APITypes.UpdatePortfolioMutation
>;
export const deletePortfolio = /* GraphQL */ `mutation DeletePortfolio(
  $input: DeletePortfolioInput!
  $condition: ModelPortfolioConditionInput
) {
  deletePortfolio(input: $input, condition: $condition) {
    id
    date
    portfolioValueUSD
    portfolioValueBTC
    totalMarketCapUSD
    totalCryptosHeld
    notableEvents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePortfolioMutationVariables,
  APITypes.DeletePortfolioMutation
>;
