/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSettings = /* GraphQL */ `query GetSettings($id: ID!) {
  getSettings(id: $id) {
    id
    noOflayersToPlace
    noOfLayersToSkip
    fatFingerDropPerc
    skipAlltrades
    skipAllBtctrades
    skipAllUsdtrades
    collectFreeCoins
    lastAlertUpdatedDate
    baseType
    marketCycle
    hideInactivePairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSettingsQueryVariables,
  APITypes.GetSettingsQuery
>;
export const listSettings = /* GraphQL */ `query ListSettings(
  $filter: ModelSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      noOflayersToPlace
      noOfLayersToSkip
      fatFingerDropPerc
      skipAlltrades
      skipAllBtctrades
      skipAllUsdtrades
      collectFreeCoins
      lastAlertUpdatedDate
      baseType
      marketCycle
      hideInactivePairs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSettingsQueryVariables,
  APITypes.ListSettingsQuery
>;
export const getBalances = /* GraphQL */ `query GetBalances($id: ID!) {
  getBalances(id: $id) {
    id
    btc {
      free
      total
      real
      __typename
    }
    usd {
      free
      total
      real
      __typename
    }
    lastUpdated
    baseType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBalancesQueryVariables,
  APITypes.GetBalancesQuery
>;
export const listBalances = /* GraphQL */ `query ListBalances(
  $filter: ModelBalancesFilterInput
  $limit: Int
  $nextToken: String
) {
  listBalances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      lastUpdated
      baseType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBalancesQueryVariables,
  APITypes.ListBalancesQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    coinID
    notation
    type
    message
    seen
    date
    baseType
    delAfterDealt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      coinID
      notation
      type
      message
      seen
      date
      baseType
      delAfterDealt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const getAlerts = /* GraphQL */ `query GetAlerts($id: ID!) {
  getAlerts(id: $id) {
    id
    buyType
    buyOrBoughtId
    status
    buyOrder {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    boughtOrder {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    delAfterDealt
    recheckCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAlertsQueryVariables, APITypes.GetAlertsQuery>;
export const listAlerts = /* GraphQL */ `query ListAlerts(
  $filter: ModelAlertsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlertsQueryVariables,
  APITypes.ListAlertsQuery
>;
export const getCoin = /* GraphQL */ `query GetCoin($id: ID!) {
  getCoin(id: $id) {
    id
    is_active
    is_new
    name
    rank
    symbol
    type
    baseType
    pairs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCoinQueryVariables, APITypes.GetCoinQuery>;
export const listCoins = /* GraphQL */ `query ListCoins(
  $filter: ModelCoinFilterInput
  $limit: Int
  $nextToken: String
) {
  listCoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCoinsQueryVariables, APITypes.ListCoinsQuery>;
export const getPairInfo = /* GraphQL */ `query GetPairInfo($id: ID!) {
  getPairInfo(id: $id) {
    id
    Coin {
      id
      is_active
      is_new
      name
      rank
      symbol
      type
      baseType
      createdAt
      updatedAt
      __typename
    }
    notation
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
      __typename
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    baseType
    layeredBuyBids {
      nextToken
      __typename
    }
    layeredBuyBoughts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPairInfoQueryVariables,
  APITypes.GetPairInfoQuery
>;
export const listPairInfos = /* GraphQL */ `query ListPairInfos(
  $filter: ModelPairInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listPairInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPairInfosQueryVariables,
  APITypes.ListPairInfosQuery
>;
export const getOrderBids = /* GraphQL */ `query GetOrderBids($id: ID!) {
  getOrderBids(id: $id) {
    id
    pairID
    buyPercent
    originalBuyPrice
    buyPrice
    highPrice
    buyHit
    bidTime
    buyColor
    invested
    stoploss
    stoplossPrice
    baseType
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderBidsQueryVariables,
  APITypes.GetOrderBidsQuery
>;
export const listOrderBids = /* GraphQL */ `query ListOrderBids(
  $filter: ModelOrderBidsFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderBids(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pairID
      buyPercent
      originalBuyPrice
      buyPrice
      highPrice
      buyHit
      bidTime
      buyColor
      invested
      stoploss
      stoplossPrice
      baseType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderBidsQueryVariables,
  APITypes.ListOrderBidsQuery
>;
export const getOrderBoughts = /* GraphQL */ `query GetOrderBoughts($id: ID!) {
  getOrderBoughts(id: $id) {
    id
    pairID
    highPrice
    buyOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    sellOrderInfo {
      id
      clientOrderId
      datetime
      timestamp
      lastTradeTimestamp
      status
      symbol
      type
      postOnly
      timeInForce
      side
      price
      average
      amount
      filled
      remaining
      cost
      __typename
    }
    realBuyOrderPlaced
    realSellOrderPlaced
    realBuyOrderStatus
    realSellOrderStatus
    buyPercent
    originalBuyPrice
    buyPrice
    bidTime
    buyTime
    buyColor
    stoploss
    stoplossPrice
    profitSecured
    profitMoved
    sellTime
    invested
    baseType
    buyTweetId
    sellTweetId
    alerts {
      id
      buyType
      buyOrBoughtId
      status
      delAfterDealt
      recheckCount
      createdAt
      updatedAt
      __typename
    }
    pairInfo {
      id
      notation
      alertPrice
      breakEvenStopLoss
      currentPrice
      higestPrice
      initialBalance
      dontTrade
      relativeLow
      relativeHigh
      reservedBalance
      baseType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderBoughtsQueryVariables,
  APITypes.GetOrderBoughtsQuery
>;
export const listOrderBoughts = /* GraphQL */ `query ListOrderBoughts(
  $filter: ModelOrderBoughtsFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderBoughts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pairID
      highPrice
      realBuyOrderPlaced
      realSellOrderPlaced
      realBuyOrderStatus
      realSellOrderStatus
      buyPercent
      originalBuyPrice
      buyPrice
      bidTime
      buyTime
      buyColor
      stoploss
      stoplossPrice
      profitSecured
      profitMoved
      sellTime
      invested
      baseType
      buyTweetId
      sellTweetId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderBoughtsQueryVariables,
  APITypes.ListOrderBoughtsQuery
>;
export const getPortfolio = /* GraphQL */ `query GetPortfolio($id: ID!) {
  getPortfolio(id: $id) {
    id
    date
    portfolioValueUSD
    portfolioValueBTC
    totalMarketCapUSD
    totalCryptosHeld
    notableEvents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPortfolioQueryVariables,
  APITypes.GetPortfolioQuery
>;
export const listPortfolios = /* GraphQL */ `query ListPortfolios(
  $filter: ModelPortfolioFilterInput
  $limit: Int
  $nextToken: String
) {
  listPortfolios(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      portfolioValueUSD
      portfolioValueBTC
      totalMarketCapUSD
      totalCryptosHeld
      notableEvents
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPortfoliosQueryVariables,
  APITypes.ListPortfoliosQuery
>;
