/* eslint-disable no-restricted-syntax */
import {
  Grid,
  Container,
  Icon,
  Segment,
  Divider,
  Button,
} from 'semantic-ui-react';
import React from 'react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import DashboardCards from '../SubComponents/dashboardCards';
import { CoinsTable } from '../SubComponents/tables/CoinsTable';
import { LargeText } from '../SubComponents/LargeText';
import { calculateTotalPnLPercentage } from '../../utils/calculations/percentageAndprice';
import { LayeredBoughtDetails } from '../../types';
import { Percentage } from '../SubComponents/Percentage';
import { calcTimeTakenForCompleteTrade } from '../../utils/calculations/timeCalculation';
import { Coin, PairInfo } from '../../API';
import { useAllCoinData } from '../hooks/useAllCoinData';
import PortfolioChart from './PortfolioChart';
import { usePortfolio } from '../hooks/usePortfolio';
import BalancePieChart from '../SubComponents/charts/BalancePieChart';
import { getBinanceTickerPrices } from '../../api/apiCalls';
import { BalanceDisplay } from '../SubComponents/Prices';
import { useGlobalSettings } from '../hooks/useGlobalSettings';

type MainDashboardProps = {};

export const getCoinTotalInvestedAndProfit = (
  layeredBuyBoughts: LayeredBoughtDetails,
  currentPrice: number,
  totalProfitLossAmt: number = 0,
  totalInvestedAmt: number = 0,
  totalCoinsWithCompletedTrade: number = 0,
  avgTimeForAllTradeToComplete: number = 0
) => {
  let totalPnL = totalProfitLossAmt;
  let totalInvested = totalInvestedAmt;
  let totalCompletedTrade = totalCoinsWithCompletedTrade;
  let avgTimeForTradesToComplete = avgTimeForAllTradeToComplete;
  const {
    profitLoss: coinProfitLossPerc,
    totalInvestedAmount,
    totalProfitLossAmount,
  } = calculateTotalPnLPercentage(layeredBuyBoughts, currentPrice);

  if (coinProfitLossPerc) {
    totalPnL += totalProfitLossAmount;
    totalInvested += totalInvestedAmount;
    totalCompletedTrade += 1;
  }

  const soldOrders = layeredBuyBoughts?.filter((order) => order.sellTime);
  const averageTimeForTradeToComplete = soldOrders?.reduce((acc, order) => {
    const { buyTime, sellTime } = order;
    const { timeTaken } = calcTimeTakenForCompleteTrade(
      buyTime as number,
      sellTime as number
    );
    return acc + timeTaken;
  }, 0);

  avgTimeForTradesToComplete = averageTimeForTradeToComplete
    ? averageTimeForTradeToComplete / soldOrders.length +
      avgTimeForAllTradeToComplete
    : avgTimeForAllTradeToComplete;

  return {
    totalProfitLossAmount: totalPnL,
    totalInvestedAmount: totalInvested,
    totalCoinsWithCompletedTrade: totalCompletedTrade,
    avgTimeForAllTradeToComplete: avgTimeForTradesToComplete,
  };
};

const filterTradeablePairs = (untransformedData: any[], notation: string) =>
  untransformedData
    .filter((data) => !data.dontTrade)
    .reduce((acc, data) => {
      const relevantPair = data.pairs.items.filter(
        (pair: { notation: string }) => pair && pair.notation === notation
      );

      if (!relevantPair.dontTrade) acc.push(relevantPair[0]);
      return acc;
    }, []);

const prepareDashboardInformation = (
  istradePairBtc: boolean,
  untransformedData: any[]
) => {
  const notation = istradePairBtc ? 'btc' : 'usd';
  let totalProfitLossAmount: number = 0;
  let totalInvestedAmount = 0;
  let totalCoinsWithCompletedTrade = 0;
  let avgTimeForAllTradeToComplete = 0;
  const tradeablePairs = filterTradeablePairs(untransformedData, notation);

  if (tradeablePairs.length <= 0) {
    return {
      avgPercentIncrease: 0,
      avgTimeForAllTradeToComplete: 0,
    };
  }

  Object.values(tradeablePairs).forEach((data: any) => {
    const { currentPrice, layeredBuyBoughts } = data;

    ({
      totalProfitLossAmount,
      totalInvestedAmount,
      totalCoinsWithCompletedTrade,
      avgTimeForAllTradeToComplete,
    } = getCoinTotalInvestedAndProfit(
      layeredBuyBoughts.items,
      currentPrice as number,
      totalProfitLossAmount,
      totalInvestedAmount,
      totalCoinsWithCompletedTrade,
      avgTimeForAllTradeToComplete
    ));
  });

  return {
    avgPercentIncrease:
      ((totalProfitLossAmount - totalInvestedAmount) / totalInvestedAmount) *
      100,
    avgTimeForAllTradeToComplete:
      avgTimeForAllTradeToComplete / totalCoinsWithCompletedTrade,
  };
};

const getPairsBasedOnNotation = (data: Coin[], notation: string) =>
  data.reduce((acc: PairInfo[], coin: Coin) => {
    const pairs = coin?.pairs?.items;
    const relevantNotationPair = pairs?.filter((pair) =>
      pair?.id.includes(notation)
    )[0];

    if (relevantNotationPair && !relevantNotationPair?.dontTrade) {
      acc.push(relevantNotationPair);
    }

    return acc;
  }, []);

const tradingNotationButtonStyle = {
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  ':hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
  },
};

const AwsDashboard: React.FC<MainDashboardProps> = () => {
  const [istradePairBtc, setIstradePairBtc] = React.useState(false);
  const [btcPrice, setBtcPrice] = React.useState<number>(0);
  const [accPercentIncrease, setAvgPercentIncrease] = React.useState<number>();
  const [daysForAllTradesToComplete, setDaysForAllTradesToComplete] =
    React.useState<number>(0);
  const notation = istradePairBtc ? 'btc' : 'usd';
  const { data, balance, getCoinDataAndBalance } = useAllCoinData();
  const portfolio = usePortfolio();
  const settings = useGlobalSettings();
  const notationBasedCoins = getPairsBasedOnNotation(data, notation);
  const [showUsdValue, setShowUsdValue] = React.useState(false);

  const noOfActiveTradingPairs = notationBasedCoins.length;
  const localeStringOption = {
    maximumFractionDigits: istradePairBtc ? 4 : 0,
  };
  const getTradeIcon = (size: IconSizeProp) => (
    <Icon
      size={size}
      name={istradePairBtc ? 'btc' : 'usd'}
      color={istradePairBtc ? 'orange' : 'green'}
    />
  );

  React.useEffect(() => {
    const { avgPercentIncrease, avgTimeForAllTradeToComplete } =
      prepareDashboardInformation(istradePairBtc, data);
    setAvgPercentIncrease(avgPercentIncrease);
    setDaysForAllTradesToComplete(avgTimeForAllTradeToComplete);
  }, [istradePairBtc, data]);

  React.useEffect(() => {
    const fetchBtcPrice = async () => {
      try {
        const prices = await getBinanceTickerPrices();
        const btcusdt = prices.find((price: any) => price.symbol === 'BTCUSDT');
        if (btcusdt) {
          setBtcPrice(Number(btcusdt.price));
        }
      } catch (error) {
        console.error('Error fetching BTC price:', error);
      }
    };

    fetchBtcPrice();
    const interval = setInterval(fetchBtcPrice, 60000); // 60 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Grid container doubling stackable className="dashboardContainer">
        {/* Charts Row */}
        <Grid.Row>
          <Grid.Column width={10}>
            {portfolio && (
              <PortfolioChart data={portfolio} backgroundColor="transparent" />
            )}
          </Grid.Column>
          <Grid.Column width={6}>
            <DashboardCards
              content={
                <Segment tertiary vertical textAlign="center">
                  {balance && (
                    <BalancePieChart
                      notationBasedCoins={notationBasedCoins}
                      balance={balance}
                      notation={notation}
                    />
                  )}
                </Segment>
              }
            />
          </Grid.Column>
        </Grid.Row>

        {/* Dashboard Cards Row */}
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid stackable columns={3} style={{ margin: 0 }}>
              {/* First Column: Total Balance and Account Increase */}
              <Grid.Column className="no-padding-mobile">
                <DashboardCards
                  content={
                    balance ? (
                      <Segment
                        tertiary
                        vertical
                        textAlign="center"
                        style={{ margin: 0 }}
                      >
                        <Grid columns={2} relaxed={false}>
                          <Grid.Column className="no-padding-mobile">
                            <h5>Total Balance</h5>
                            <BalanceDisplay
                              value={balance?.[notation].total}
                              istradePairBtc={istradePairBtc}
                              btcPrice={btcPrice}
                              showUsdValue={showUsdValue}
                              onMouseEnter={() => setShowUsdValue(true)}
                              onMouseLeave={() => setShowUsdValue(false)}
                              localeStringOption={localeStringOption}
                            />
                          </Grid.Column>
                          <Grid.Column className="no-padding-mobile">
                            <h5>Account Increase</h5>
                            <LargeText>
                              {accPercentIncrease && (
                                <Percentage percentage={accPercentIncrease}>
                                  {`${accPercentIncrease.toFixed(0)} %`}
                                </Percentage>
                              )}
                            </LargeText>
                          </Grid.Column>
                        </Grid>
                        <Divider vertical className="hide-on-mobile">
                          &
                        </Divider>
                      </Segment>
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid.Column>

              {/* Second Column: Balance In Trade and Trading Notation */}
              <Grid.Column className="no-padding-mobile">
                <DashboardCards
                  content={
                    balance ? (
                      <Segment
                        tertiary
                        vertical
                        textAlign="center"
                        style={{ margin: 0 }}
                      >
                        <Grid columns={2} relaxed={false}>
                          <Grid.Column className="no-padding-mobile">
                            <h5>Balance In Trade</h5>
                            <BalanceDisplay
                              value={balance?.[notation].real}
                              istradePairBtc={istradePairBtc}
                              btcPrice={btcPrice}
                              showUsdValue={showUsdValue}
                              onMouseEnter={() => setShowUsdValue(true)}
                              onMouseLeave={() => setShowUsdValue(false)}
                              localeStringOption={localeStringOption}
                            />
                          </Grid.Column>
                          <Grid.Column className="no-padding-mobile">
                            <h5>Trading Notation</h5>
                            <Button
                              size="small"
                              as="div"
                              onClick={() => setIstradePairBtc(!istradePairBtc)}
                              style={tradingNotationButtonStyle}
                              secondary
                              animated="fade"
                            >
                              <Button.Content
                                visible
                                style={{ minWidth: '80px' }}
                              >
                                {getTradeIcon('large')}
                              </Button.Content>
                              <Button.Content
                                hidden
                                style={{ minWidth: '80px' }}
                              >
                                {istradePairBtc
                                  ? 'switch to USD'
                                  : 'switch to BTC'}
                              </Button.Content>
                            </Button>
                          </Grid.Column>
                        </Grid>
                        <Divider vertical className="hide-on-mobile">
                          &
                        </Divider>
                      </Segment>
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid.Column>

              {/* Third Column: Days Taken and Active Pairs */}
              <Grid.Column className="hide-on-mobile no-padding-mobile">
                <DashboardCards
                  content={
                    data ? (
                      <Segment
                        tertiary
                        vertical
                        textAlign="center"
                        style={{ margin: 0 }}
                      >
                        <Grid columns={2} relaxed={false}>
                          <Grid.Column>
                            <h5>Days Taken</h5>
                            <LargeText
                              vol={`${daysForAllTradesToComplete.toFixed(0)}`}
                              icon="thumbs up"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <h5>Active Pairs</h5>
                            <LargeText vol={noOfActiveTradingPairs} icon="gg" />
                          </Grid.Column>
                        </Grid>
                        <Divider vertical className="hide-on-mobile">
                          &
                        </Divider>
                      </Segment>
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* Table Row */}
      <Grid.Row>
        <Grid.Column>
          {data && (
            <CoinsTable
              istradePairBtc={istradePairBtc}
              untransformedData={data}
              activateDontTradeForCoin={getCoinDataAndBalance}
              hideInactivePairs={settings?.hideInactivePairs || false}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Container>
  );
};

export default AwsDashboard;
