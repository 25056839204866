import {
  IChartApi,
  IPriceLine,
  ISeriesApi,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';
/* eslint-disable camelcase */
import {
  CreateOrderBoughtsInput,
  HighLowPrice,
  OrderBids,
  OrderBoughts,
} from '../API';

export type MainChartProps = {
  mainChart: IChartApi;
  mainCandlestickSeries: ISeriesApi<'Candlestick'>;
};

export type CoinpaprikaCoin = {
  id: string;
  name: string;
  symbol: string;
  rank: number;
  is_new: boolean;
  is_active: boolean;
  type: string;
};

export type Settings = {
  globalSettings: GlobalSettings;
  marketSettings: MarketSettings;
};

const validMarketCycles = ['bull', 'bear', 'violent', 'accumulation'] as const;
export type MarketCycle = (typeof validMarketCycles)[number];

export type GlobalSettings = {
  noOflayersToPlace: number;
  noOfLayersToSkip: number;
  fatFingerDropPerc: number;
  skipAlltrades: boolean;
  skipAllBtctrades: boolean;
  skipAllUsdtrades: boolean;
  collectFreeCoins: boolean;
  marketCycle: MarketCycle;
  hideInactivePairs: boolean;
};

export type MarketSettings = {
  lastAlertUpdatedDate: number;
};

export type TradeNotationsUpperCase = 'USD' | 'BTC';

export type AvgHighLowsPerc = {
  lowLows: number;
  avgLows: number;
  lowHighs: number;
  avgHighs: number;
};

export type CoinInformation = {
  btcPair: TradeDetails;
  usdPair: TradeDetails;
  id: string;
  is_active: boolean;
  is_new: boolean;
  name: string;
  rank: number;
  symbol: string;
  type: string;
  dontTrade?: boolean;
};

export type AWSCoinInformation = {
  id: string;
  Coin: {
    name: string;
    rank: number;
    symbol: string;
  };
  alertPrice: number;
  currentPrice: number;
  higestPrice: number;
  highsAndLows: Omit<HighLowPrice, '__typename'>[];
  initialBalance: number;
  layeredBuyBoughts: { items: LayeredBoughtDetails };
  layeredBuyBids: { items: LayeredBuyBidDetails };
  relativeHigh: number;
  relativeLow: number;
  reservedBalance: number;
  breakEvenStopLoss: boolean;
  paperTrade: boolean;
  dontTrade?: boolean;
};

export type KeyForTradePairs = keyof Pick<
  CoinInformation,
  'btcPair' | 'usdPair'
>;

// TODO: use generics so we can pass orderBids as a parameter
export type OrderBidsWithoutDynamoSpecificKeys = Omit<
  OrderBids,
  '__typename' | 'baseType' | 'createdAt' | 'updatedAt'
>;

export type OrderBoughtsWithoutDynamoSpecificKeys = Omit<
  OrderBoughts,
  '__typename' | 'baseType' | 'createdAt' | 'updatedAt'
>;

export type LayeredBuyBid = OrderBidsWithoutDynamoSpecificKeys;

export type LayeredBought = OrderBoughtsWithoutDynamoSpecificKeys;

export type LayeredBuyBidDetails = LayeredBuyBid[];

export type LayeredBoughtDetails = LayeredBought[];

export type Balances = {
  btc: Balance;
  usd: Balance;
  lastUpdated?: number;
};

type Balance = {
  free: number;
  total: number;
  real: number;
};

export type LinesInChart = {
  highPriceLine: IPriceLine | undefined;
  lowPriceLine: IPriceLine | undefined;
  alertPriceLine: IPriceLine | undefined;
  breakEvenLine: IPriceLine | undefined;
  buyLines: BuyLines | undefined;
  boughtLines: SellLines | undefined;
};

export type BuyLines = {
  [key: string]: BuyOrderLines;
};

export type SellLines = {
  [key: string]: SellOrderLines;
};

export type BuyOrderLines = {
  buyLine: IPriceLine | undefined;
  stopLossLine: IPriceLine | undefined;
};

export type SellOrderLines = {
  stopLossLine: IPriceLine | undefined;
};

export type TradeNotations = 'usd' | 'btc';

export type TradeDetailsDashBoard = {
  isCurrentlyInTrade: boolean;
  hasStopLossPrice: boolean;
  stopLossPerc: number;
  hasRealOrder: boolean;
  finalTrade: CreateOrderBoughtsInput | undefined;
};

export type PercentageStatistics = {
  lowestAlertPercentage: number;
  averageAlertPercentage: number;
  lowestProfitPercentage: number;
  averageProfitPercentage: number;
};

type TradeDetails = {
  alertPrice: number;
  currentPrice: number;
  currentUSDPrice: number;
  higestPrice: number;
  highsAndLows: Omit<HighLowPrice, '__typename'>[];
  initialBalance: number;
  layeredBuyBoughts: LayeredBoughtDetails;
  layeredBuyBids: LayeredBuyBidDetails;
  isCurrentlyInTrade: boolean;
  relativeHigh: number;
  relativeLow: number;
  reservedBalance: number;
  breakEvenStopLoss: boolean;
  paperTrade: boolean;
  dontTrade?: boolean;
};

export type SortTradeArgs = {
  values: {
    [key: string]: TradeDetailsDashBoard;
  };
};

export type SortAlertArgs = {
  values: {
    [key: string]: DashboardCoin['alerts'];
  };
};

export type SortBuyTimeArgs = {
  values: {
    [key: string]: number;
  };
};

export type CoinOrdersTable = {
  percAndColor: [number, string];
  invested?: number | null;
  noOfCoins?: string | null;
  buyPrice: number;
  buyTime: number | null;
  stoplossInfo:
    | [boolean | null | undefined, number | null | undefined]
    | undefined;
  profitSecured: number | undefined | null;
  sellTime?: number;
  timeTaken: number | null;
  orderInfo: [boolean];
  orderData: LayeredBuyBid | LayeredBought;
};

export type DashboardCoin = {
  rank: number;
  coinDetails: [string, string, string];
  currentPrice: number;
  currentAlternatePrice: number;
  fromFourMonthATH: number;
  relativeHigh: number;

  fromATH: number;
  higestPrice: number;
  alerts: { hasAlertTriggered: boolean; hasStoploss: boolean };
  tradeDetails: TradeDetailsDashBoard;
  symbol: string;
};

export type DataProp = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  usd: number;
};

export type DataPropWithTime = {
  time: Time | UTCTimestamp;
  open: number;
  high: number;
  low: number;
  close: number;
  usd: number;
};

export type DeletedBuyBidsReturn = {
  buyBoughts: LayeredBoughtDetails;
  remainingBuyBids: LayeredBuyBidDetails;
  remainingBalance: number;
  remReservedBalance: number;
};

export const hasDeletedBuyBids = (val: unknown): val is DeletedBuyBidsReturn =>
  (val as DeletedBuyBidsReturn).remainingBuyBids !== undefined;

export const isLayerBuyBought = (val: unknown): val is LayeredBought =>
  (val as LayeredBought).buyTime !== undefined;

export type Notification = {
  id: string;
  coinID?: string;
  notation: TradeNotations;
  type: 'error' | 'success' | 'warning';
  message: string;
  seen: boolean;
  date: number;
};

export type NotificationsProps = {
  [key: string]: Notification;
};

export type PercForHighLow = {
  perc: number;
  priceType: string;
};
