import { Form, Formik } from 'formik';
import React from 'react';
import {
  Container,
  Divider,
  Grid,
  Segment,
  Label,
  Input,
  Button,
  Checkbox,
  Header,
  Dropdown,
  DropdownProps,
  Icon,
} from 'semantic-ui-react';
import AwsDatabase from '../../../database/Database';
import { Settings } from '../../../types';
import { MarketCycle } from '../../../API';

const AwsDB = new AwsDatabase();

export type SettingsFormProps = {
  settings: Settings['globalSettings'];
};

export const saveSettingsToDB = async ({
  noOflayersToPlace,
  noOfLayersToSkip,
  fatFingerDropPerc,
  skipAlltrades,
  skipAllBtctrades,
  skipAllUsdtrades,
  collectFreeCoins,
  marketCycle,
  hideInactivePairs,
}: Settings['globalSettings']) => {
  await AwsDB.saveGlobalSetting({
    noOflayersToPlace,
    noOfLayersToSkip,
    fatFingerDropPerc,
    skipAlltrades,
    skipAllBtctrades,
    skipAllUsdtrades,
    collectFreeCoins,
    marketCycle,
    hideInactivePairs,
  });
};

const styles = {
  segmentHeader: {
    fontSize: '1rem',
    fontWeight: 600,
    color: '#546E7A',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  segment: {
    padding: '16px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    border: '1px solid #E0E0E0',
  },
};

export const SettingsForm: React.FC<SettingsFormProps> = ({ settings }) => (
  <Formik
    initialValues={{
      noOflayersToPlace: settings.noOflayersToPlace || 5,
      noOfLayersToSkip: settings.noOfLayersToSkip || 0,
      fatFingerDropPerc: settings.fatFingerDropPerc || 25,
      skipAlltrades: settings.skipAlltrades || false,
      skipAllBtctrades: settings.skipAllBtctrades || false,
      skipAllUsdtrades: settings.skipAllUsdtrades || false,
      collectFreeCoins: settings.collectFreeCoins || false,
      marketCycle: settings.marketCycle || MarketCycle.bear,
      hideInactivePairs: settings.hideInactivePairs || false,
    }}
    onSubmit={async (values, { setSubmitting }) => {
      try {
        await saveSettingsToDB(values);
      } catch (error) {
        console.error('Error saving settings:', error);
      } finally {
        setSubmitting(false);
      }
    }}
    validate={(values) => {
      const errors: {
        noOflayersToPlace?: string;
        noOfLayersToSkip?: string;
        fatFingerDropPerc?: string;
      } = {};
      if (values.noOflayersToPlace < 0) {
        errors.noOflayersToPlace = 'Total Layers number is required';
      }
      if (values.noOfLayersToSkip < 0) {
        errors.noOfLayersToSkip = 'Skip Layers number is required';
      }
      if (values.fatFingerDropPerc < 0) {
        errors.fatFingerDropPerc = 'Fatfinger drop % required';
      }
      return errors;
    }}
  >
    {(props) => {
      const { values, errors, isSubmitting, handleChange, setFieldValue } =
        props;

      const marketCycleOptions = [
        { key: 'bear', text: 'Bear', value: MarketCycle.bear },
        { key: 'bull', text: 'Bull', value: MarketCycle.bull },
        { key: 'violent', text: 'Violent', value: MarketCycle.violent },
        {
          key: 'accumulation',
          text: 'Accumulation',
          value: MarketCycle.accumulation,
        },
      ];

      return (
        <Container style={{ margin: '16px auto' }}>
          <Grid container padded="vertically" inverted verticalAlign="middle">
            <Form>
              <Grid columns={3} stackable container>
                <Grid.Column>
                  <Segment raised style={styles.segment}>
                    <div style={styles.segmentHeader}>
                      <Icon name="settings" />
                      Total Layers
                    </div>
                    <Input
                      transparent
                      required
                      id="noOflayersToPlace"
                      type="number"
                      placeholder="Total layers to place"
                      value={values.noOflayersToPlace}
                      onChange={handleChange}
                    />
                    {errors.noOflayersToPlace && (
                      <Label basic color="red" pointing className="floatRight">
                        {errors.noOflayersToPlace}
                      </Label>
                    )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment raised style={styles.segment}>
                    <div style={styles.segmentHeader}>
                      <Icon name="settings" />
                      Skip Layers
                    </div>
                    <Input
                      transparent
                      required
                      id="noOfLayersToSkip"
                      placeholder="Num of layers to skip"
                      type="number"
                      value={values.noOfLayersToSkip}
                      onChange={handleChange}
                    />
                    {errors.noOfLayersToSkip && (
                      <Label basic color="red" pointing className="floatRight">
                        {errors.noOfLayersToSkip}
                      </Label>
                    )}
                  </Segment>
                </Grid.Column>
              </Grid>
              <Divider />
              <Container>
                <Header
                  as="h5"
                  floated="right"
                  className="infoHeader"
                  style={{ opacity: 0.6 }}
                >
                  These only skips real Buy orders and does not impact the real
                  sell orders.
                  <br />
                  Buy orders will be converted to paper trades
                </Header>

                <Grid columns={3} container doubling stackable>
                  <Grid.Column>
                    <Segment raised style={styles.segment}>
                      <div style={styles.segmentHeader}>
                        <Icon name="ban" />
                        Skip all trades
                      </div>
                      <Checkbox
                        toggle
                        className="vmiddle"
                        id="skipAlltrades"
                        name="skipAlltrades"
                        fitted
                        defaultChecked={values.skipAlltrades}
                        onChange={handleChange}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment raised style={styles.segment}>
                      <div style={styles.segmentHeader}>
                        <Icon name="ban" />
                        Skip all BTC trades
                      </div>
                      <Checkbox
                        toggle
                        className="vmiddle"
                        id="skipAllBtctrades"
                        name="skipAllBtctrades"
                        fitted
                        defaultChecked={values.skipAllBtctrades}
                        onChange={handleChange}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment raised style={styles.segment}>
                      <div style={styles.segmentHeader}>
                        <Icon name="ban" />
                        Skip all USD trades
                      </div>
                      <Checkbox
                        toggle
                        className="vmiddle"
                        id="skipAllUsdtrades"
                        name="skipAllUsdtrades"
                        fitted
                        defaultChecked={values.skipAllUsdtrades}
                        onChange={handleChange}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid>
                <Divider />
              </Container>
              <Grid columns={2} container>
                <Grid.Column>
                  <Segment raised style={styles.segment}>
                    <div style={styles.segmentHeader}>
                      <Icon name="angle double down" />
                      Fatfinger drop %
                    </div>
                    <Input
                      transparent
                      required
                      id="fatFingerDropPerc"
                      type="number"
                      placeholder="Fat finger drop %"
                      disabled
                      value={values.fatFingerDropPerc}
                      onChange={handleChange}
                    />
                    {errors.fatFingerDropPerc && (
                      <Label basic color="red" pointing className="floatRight">
                        {errors.fatFingerDropPerc}
                      </Label>
                    )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment raised style={styles.segment}>
                    <div style={styles.segmentHeader}>
                      <Icon name="save outline" />
                      Collect Free Coins?
                    </div>
                    <Checkbox
                      toggle
                      className="vmiddle"
                      id="collectFreeCoins"
                      name="collectFreeCoins"
                      fitted
                      defaultChecked={values.collectFreeCoins}
                      onChange={handleChange}
                    />
                    {errors.collectFreeCoins && (
                      <Label basic color="red" pointing className="floatRight">
                        {errors.collectFreeCoins}
                      </Label>
                    )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment raised style={styles.segment}>
                    <div style={styles.segmentHeader}>
                      <Icon name="chart line" />
                      Market Cycle
                    </div>
                    <Dropdown
                      selection
                      id="marketCycle"
                      name="marketCycle"
                      options={marketCycleOptions}
                      value={values.marketCycle}
                      onChange={(_: any, data: DropdownProps) =>
                        setFieldValue('marketCycle', data.value)
                      }
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
              <Divider />
              <Grid columns={3} container>
                <Grid.Column>
                  <Segment raised style={styles.segment}>
                    <div style={styles.segmentHeader}>
                      <Icon name="eye slash" />
                      Hide Inactive Pairs
                    </div>
                    <Checkbox
                      toggle
                      className="vmiddle"
                      id="hideInactivePairs"
                      name="hideInactivePairs"
                      fitted
                      defaultChecked={values.hideInactivePairs}
                      onChange={handleChange}
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
              <Divider />
              <Grid columns={1} container>
                <Grid.Column>
                  <Segment basic>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      color="green"
                      size="medium"
                      className="marginAuto"
                    >
                      Save New Settings
                    </Button>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Form>

            {process.env.NODE_ENV !== 'production' && (
              <Grid
                container
                textAlign="center"
                style={{ opacity: 0.3, marginTop: '64px' }}
              >
                which to trade
                <Button.Group>
                  <Button>BTC</Button>
                  <Button.Or />
                  <Button>USD</Button>
                  <Button.Or />
                  <Button>BOTH</Button>
                </Button.Group>
              </Grid>
            )}
          </Grid>
        </Container>
      );
    }}
  </Formik>
);
