import axios from 'axios';
import {
  DataProp,
  LayeredBuyBid,
  LayeredBought,
  TradeNotations,
} from '../types';
import { getSalesData } from '../utils/salesPageUtils';
import { getKlineData } from '../utils/binanceHistoricalWrapper';
import { BarData, Time } from 'lightweight-charts';
import { millisecToSec } from '../backtest-node/utils';

const TWEET_TRADES_URL =
  'https://aljn817hni.execute-api.eu-central-1.amazonaws.com/tweetTrades-getrich';
const ALERT_LAMBDA_URL =
  'https://aljn817hni.execute-api.eu-central-1.amazonaws.com/reactToAlerts-getrich';
const GET_RICH_SLOW_URL =
  'https://aljn817hni.execute-api.eu-central-1.amazonaws.com/getSalesPageData-getrich';
const BINANCE_TICKERS_PRICES = 'https://api.binance.com/api/v3/ticker/price';

const COIN_PAPRIKA_GLOBAL = 'https://api.coinpaprika.com/v1/global';

export type TweetLambdaProps = {
  coinID: string;
  notation: string;
  buyType: string;
  candleData: DataProp;
  order: LayeredBuyBid | LayeredBought;
};

export const getTotalMarketCapData = async () => {
  const response = await axios.get(COIN_PAPRIKA_GLOBAL);
  return response.data.market_cap_usd;
};

export const historicalApiUrl = (
  symbol: string,
  notation: TradeNotations,
  timeType: string,
  time: number
) =>
  `https://min-api.cryptocompare.com/data/v2/histo${timeType}?fsym=${symbol}&tsym=${notation.toUpperCase()}&limit=${time}`;

export const getHistoricalDataForCryptocompareCoin = async (
  endpoint: string
) => {
  const response = await axios.get(endpoint);
  return response.data.Data.Data;
};

export const getHistoricalDataForCoin = async (
  symbol: string,
  tradeNotation: string,
  interval: '1h' | 'day' = 'day',
  limit: number = 2000
): Promise<BarData[]> => {
  try {
    const binanceInterval = interval === 'day' ? '1d' : '1h';

    // Calculate start date based on limit
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - limit);

    const klineData = await getKlineData(
      symbol,
      tradeNotation,
      binanceInterval,
      startDate,
      endDate,
      limit
    );

    // Convert Binance data to BarData format
    return klineData.map((kline: any) => ({
      time: millisecToSec(kline.openTime) as Time,
      open: Number(kline.open),
      high: Number(kline.high),
      low: Number(kline.low),
      close: Number(kline.close),
    }));
  } catch (error) {
    console.error('Error fetching historical data:', error);
    return [];
  }
};

export const geFirstXCoins = async (endpoint: string, max: number) => {
  const response = await axios.get(endpoint);
  return response.data.slice(0, max);
};

export const getBinanceTickerPrices = async () => {
  const response = await axios.get(BINANCE_TICKERS_PRICES);
  return response.data;
};

export const callLambdaToPostTweet = (event: TweetLambdaProps) => {
  // we stop posting to twitter because of API changes that costs money
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return null;
  }

  console.log(`calling tweetTrades Lambda function for ${event.coinID}`);
  axios
    .post(TWEET_TRADES_URL, event)
    .then((res) => {
      console.log(
        `Success - response from lambda res --> ${res.status} ${res.data}`
      );
    })
    .catch((error) => {
      console.log('Error when invoking lambda tweet', error.message);
    });

  return null;
};

type AlertLambdaProps = {
  currentPrice: string;
  alertID: string;
  stoplossPrice: number;
};
export const callLambdaReactAlert = (payload: AlertLambdaProps) => {
  axios
    .post(ALERT_LAMBDA_URL, payload)
    .then((res) => {
      console.log(
        `Success - response from lambda res --> ${res.status} ${res.data}`
      );
    })
    .catch((error) => {
      console.log('Error when invoking lambda alert', error.message);
    });
};

export const getSalesPageData = async () => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    console.log('pretend we are getting sales page data from lambda');
    const salesPageData = await getSalesData();

    return salesPageData;
  }

  const response = await axios.get(GET_RICH_SLOW_URL);
  return response.data;
};
